import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  ${({ radius = 0 }) => (radius > 0 ? `border-radius: ${radius}px` : ``)};
  border: ${props => (props.hasBorder ? '1px solid gray' : null)};
  cursor: ${props => (props.clickable ? 'pointer' : null)};
`;
