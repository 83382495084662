import React, { useState } from 'react';
import P from 'prop-types';

import { useContactContext } from '@context/contact-context';
import { useWindowSize } from '@hooks/useWindowSize';
import { Button, Image, Link, Text, Title } from '../../atoms';
import { TextAction, TextClipBoard } from '../../organisms';
import * as S from './styled';
import { Map } from '../../atoms/index';

const mobileWidth = 768;

const LocationCard = ({
  title,
  personName,
  phoneNumber,
  positionName,
  address,
  imageSrc,
  coordinates,
  isAvailable,
}) => {
  const { width } = useWindowSize();
  const { messages } = useContactContext();

  let formatedAddress = address.line.trim().replaceAll(', ,', ',');
  formatedAddress = formatedAddress.replaceAll(',  -', '-');
  formatedAddress = formatedAddress.replaceAll('- ,', '-');
  formatedAddress = /[A-Zà-ú]/i.test(formatedAddress.substr(-1))
    ? formatedAddress
    : formatedAddress.replace(/.$/, '.');

  function isMobile() {
    return width <= mobileWidth;
  }

  function handleClickPhoneNumber(text) {
    const cta = document.createElement('a');
    cta.href = `tel:${text}`;
    cta.click();
  }

  return (
    <S.Container>
      {!isMobile() && (
        <S.TitleWrap>
          <Title color="#14BEA4">{title}</Title>
        </S.TitleWrap>
      )}
      <S.Head>
        <S.HeadText>
          <Title variant="light" color="#000000">
            {personName}
          </Title>
          <Text color="#707070" variant="medium">
            {positionName}
          </Text>
        </S.HeadText>
        {phoneNumber && !isMobile() && (
          <Button type="outlined" capitalize disabled={!isAvailable}>
            <Link
              href={`tel:${phoneNumber}`}
              role="link"
              aria-disabled={isAvailable}
              disabled={!isAvailable}
            >
              {messages['call.now']}
            </Link>
          </Button>
        )}
        {isMobile() && (
          <S.TitleWrap>
            <Title color="#14BEA4">{title}</Title>
          </S.TitleWrap>
        )}
      </S.Head>

      {isMobile() && phoneNumber && (
        <S.TextActionWrap disabled={!isAvailable}>
          <TextAction
            handleClick={handleClickPhoneNumber}
            text={phoneNumber}
            disabled={!isAvailable}
          >
            {messages['call']}
          </TextAction>
        </S.TextActionWrap>
      )}

      <S.ClipBoardWrap>
        {address?.address ? (
          <TextClipBoard text={formatedAddress}>
            {messages['copy']}
          </TextClipBoard>
        ) : null}
      </S.ClipBoardWrap>
      {!coordinates.latitude || !coordinates.longitude ? (
        <div style={{ height: 238 }}></div>
      ) : (
        <Map info={coordinates} />
      )}
    </S.Container>
  );
};

LocationCard.propTypes = {
  title: P.string,
  personName: P.string,
  phoneNumber: P.string,
  positionName: P.string,
  address: P.object,
  imageSrc: P.string,
  isAvailable: P.bool,
  coordinates: P.shape({
    latitude: P.oneOfType([P.number, P.string]),
    longitude: P.oneOfType([P.number, P.string]),
  }),
};

export default LocationCard;
