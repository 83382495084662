import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 1;

  &:active {
    cursor: grab;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 520px) {
    padding: 0;
  }

  .slick-slide {
    padding-left: 1px;
  }
`;

export const IconRight = styled.div`
  top: 50%;
  transform: translateY(-50%);
  left: -121px;
  position: absolute;

  @media only screen and (max-width: 1439px) {
    left: -80px;
  }

  @media only screen and (max-width: 1000px) {
    left: -30px;
  }

  @media only screen and (max-width: 520px) {
    left: -35px;

    button {
      background-color: transparent;
    }
  }
`;

export const IconLeft = styled.div`
  top: 50%;
  transform: translateY(-50%);
  right: -121px;
  position: absolute;

  @media only screen and (max-width: 1439px) {
    right: -80px;
  }

  @media only screen and (max-width: 1000px) {
    right: -30px;
  }

  @media only screen and (max-width: 520px) {
    right: -35px;

    button {
      background-color: transparent;
    }
  }
`;
