import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const Image = ({ src, radius, hasBorder, clickable, ...rest }) => {
  return (
    <S.Image
      src={src}
      radius={radius}
      hasBorder={hasBorder}
      clickable={clickable}
      {...rest}
    />
  );
};

Image.propTypes = {
  src: P.string.isRequired,
  alt: P.string.isRequired,
  hasBorder: P.bool,
  radius: P.number,
  clickable: P.bool,
};

Image.defaultProps = {
  radius: 0,
  hasBorder: false,
  clickable: false,
};

export default Image;
