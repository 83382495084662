import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const Text = styled.div`
  visibility: hidden;
  background-color: #555;
  font-size: 12px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 99;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  ${({ isVisible = false }) =>
    isVisible &&
    `visibility: visible;
     opacity: 1;
  `}
`;
