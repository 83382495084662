import React, { useState, useEffect } from 'react';
import { Image, Text, Title } from './atoms';
import { LocationCard, Toggle } from './molecules';
import { FormContactUs, Slider } from './organisms';
import { Container } from './templates';
import { ContactContext } from '@context/contact-context';
import { useWindowSize } from '@hooks/useWindowSize';
import ImageHero from '@images/contact-page-hero.png';
import Mapa from '@images/Mapa.png';
import FlapperContact from './assets/images/flapper-contact.png';
import * as S from './styled';
import { getContactInfo } from '@services/contact';
import { translate as t, useCurrentLang } from '@utils/translate';

const mobileWidth = 768;

const Contact = () => {
  const { width } = useWindowSize();
  const [locationsData, setLocationsData] = useState([]);
  const lang = useCurrentLang();

  const TEXTS = {
    CONTACT_HERO_TITLE: t('contactHeroTitle'),
    CONTACT_HERO_TEXT: t('contactHeroText'),
    NAME: t('name'),
    EMAIL: t('email'),
    SUBJECT: t('subject'),
    MESSAGE: t('message'),
    CONFIRM_EMAIL: t('confirmEmail'),
    SEND: t('send'),
    VIEW_NUMBER: t('viewNumber'),
    CALL_NOW: t('callNow'),
    COPY: t('copy'),
    COPIED: t('copied'),
    CALL: t('call'),
  };

  const data = {
    messages: {
      'form.title': TEXTS.CONTACT_HERO_TITLE,
      'form.subtitle': TEXTS.CONTACT_HERO_TEXT,
      'form.field.name': TEXTS.NAME,
      'form.field.email': TEXTS.EMAIL,
      'form.field.subject': TEXTS.SUBJECT,
      'form.field.message': TEXTS.MESSAGE,
      'form.field.emailconfirmation': TEXTS.CONFIRM_EMAIL,
      'form.field.button': TEXTS.SEND,
      'call.now': TEXTS.CALL_NOW,
      copy: TEXTS.COPY,
      call: TEXTS.CALL,
      copied: TEXTS.COPIED,
    },
  };

  useEffect(() => {
    (async () => {
      const {
        data: { regionGroups, result },
      } = await getContactInfo({ params: { region: 1, bringInactive: 0 } });

      const regionsWithContacts = regionGroups.filter(
        region => region.contacts.length
      );

      if (result?.status === 0) {
        setLocationsData(regionsWithContacts);
      }
    })();
  }, [lang]);

  return (
    <ContactContext.Provider value={data}>
      <S.Container>
        <S.OvelayLeft />
        <S.OvelayRight />
        <S.Section>
          <S.FormWrap>
            <S.TitleWrap>
              <Title>{TEXTS.CONTACT_HERO_TITLE}</Title>
            </S.TitleWrap>
            <S.SubTitleWrap>
              <Text weight={500}>{TEXTS.CONTACT_HERO_TEXT}</Text>
            </S.SubTitleWrap>
            <FormContactUs />
          </S.FormWrap>
          <Image
            width="100%"
            height="349"
            src={ImageHero}
            radius={23}
            alt="Flapper Contact"
          />
        </S.Section>
      </S.Container>

      {locationsData.map(region => (
        <Toggle key={region.id} title={region.name}>
          {width <= mobileWidth ? (
            <S.CardsWrap>
              {region.contacts.map((contact, index) => (
                <S.CardWrap key={index}>
                  <LocationCard
                    key={contact.id}
                    title={contact?.address?.country?.name || ''}
                    personName={contact?.operatorName}
                    phoneNumber={contact?.phoneNumber}
                    positionName={contact?.label || ''}
                    address={contact?.address || ''}
                    isAvailable={contact?.isAvailable}
                    imageSrc={Mapa}
                    coordinates={{
                      latitude: Number(contact?.address?.latitude),
                      longitude: Number(contact?.address?.longitude),
                    }}
                  />
                </S.CardWrap>
              ))}
            </S.CardsWrap>
          ) : (
            <Slider>
              {region.contacts.map(contact => (
                <LocationCard
                  key={contact.id}
                  title={contact?.address?.country?.name || ''}
                  personName={contact?.operatorName}
                  phoneNumber={contact?.phoneNumber}
                  positionName={contact?.label || ''}
                  address={contact?.address || ''}
                  isAvailable={contact?.isAvailable}
                  imageSrc={Mapa}
                  coordinates={{
                    latitude: Number(contact?.address?.latitude),
                    longitude: Number(contact?.address?.longitude),
                  }}
                  clickable
                />
              ))}
            </Slider>
          )}
        </Toggle>
      ))}

      <S.ImageBottom>
        <Container>
          <Image
            width="100%"
            height="490"
            src={FlapperContact}
            radius={width > mobileWidth ? 23 : 10}
            alt="Flapper"
          />
        </Container>
      </S.ImageBottom>
    </ContactContext.Provider>
  );
};

export default Contact;
