import styled, { keyframes } from 'styled-components';

const fadeUp = keyframes`
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const FormContactUs = styled.form`
  width: 100%;
  margin-top: 47px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(3, auto) 46px;
  grid-column-gap: 14px;
  grid-row-gap: 12px;

  .textarea {
    grid-row: span 3 / auto;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto 115px auto auto auto;
  }
`;

export const Feedback = styled.span`
  display: inline-block;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeUp} 0.3s ease-in-out forwards;
  text-transform: capitalize;
`;
