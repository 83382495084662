import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const Link = ({ children, capitalize, disabled, ...rest }) => {
  return (
    <S.Container rel="noopener noreferrer" {...rest} disabled={disabled}>
      {children}
    </S.Container>
  );
};

Link.propTypes = {
  children: P.node,
  capitalize: P.bool,
  disabled: P.bool,
};

export default Link;
