import React from 'react';
import P from 'prop-types';
import { Children, useEffect, useState } from 'react';
import SliderReact from 'react-slick';

import { useWindowSize } from '@hooks/useWindowSize';
import { array_chunk } from '@utils/array_chunk';
import { ArrowLeft } from '../../atoms';
import { IconButton, Slide } from '../../molecules';
import * as S from './styled';

const itemsPerPage = 1;
const mobileWidth = 768;
const desktopWidth = 1040;

const Slider = ({ children }) => {
  const size = useWindowSize();

  function getSize() {
    if (size.width <= mobileWidth) return 1;

    if (size.width <= desktopWidth && size.width > mobileWidth) return 2;

    return 3;
  }

  const childrenList = Children.toArray(children);
  const lists = array_chunk(childrenList, getSize());

  const [slider, setSlider] = useState();
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(lists.length > 1);

  function handleNext() {
    slider.slickNext();
  }

  function handlePrev() {
    slider.slickPrev();
  }

  function handleAfterChange(currentSlideIndex) {
    if (currentSlideIndex === 0) {
      setShowPrev(false);
    }

    if (currentSlideIndex >= itemsPerPage) {
      setShowPrev(true);
    }

    if (lists.length - currentSlideIndex <= itemsPerPage) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: itemsPerPage,
    slidesToScroll: itemsPerPage,
    touchMove: true,
    centerPadding: '100px',
    adaptiveHeight: true,
    afterChange: handleAfterChange,
  };

  useEffect(() => {
    slider?.slickGoTo(0, true);
  }, [slider]);

  return (
    <S.Container>
      {showPrev && (
        <S.IconRight>
          <IconButton radius="0 13px 13px 0" onClick={handlePrev}>
            <ArrowLeft rotate={-180} />
          </IconButton>
        </S.IconRight>
      )}

      <SliderReact
        ref={currentSlider => setSlider(currentSlider)}
        {...settings}
      >
        {lists.map((list, index) => {
          return <Slide key={index.toString()}>{list.map(elem => elem)}</Slide>;
        })}
      </SliderReact>

      {showNext && (
        <S.IconLeft>
          <IconButton radius="13px 0 0 13px" onClick={handleNext}>
            <ArrowLeft />
          </IconButton>
        </S.IconLeft>
      )}
    </S.Container>
  );
};

Slider.propTypes = {
  children: P.node,
};

export default Slider;
