import styled from 'styled-components';

const handleType = (variant, isMobile) => {
  switch (variant) {
    case 'extra-light':
      return 'font-size: 16px; line-height: 19px;';
    case 'light':
      return 'font-size: 18px; line-height: 22px;';
    case 'regular':
      if (isMobile) return 'font-size: 18px; line-height: 22px;';

      return 'font-size: 20px; line-height: 24px;';
    case 'medium':
      if (isMobile) return 'font-size: 20px; line-height: 24px;';

      return 'font-size: 24px; line-height: 29px;';
    default:
      if (isMobile) return 'font-size: 24px; line-height: 29px;';

      return 'font-size: 30px; line-height: 37px;';
  }
};

export const Title = styled.p`
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  transition: 0.2s font-size;
  &::first-letter {
    text-transform: uppercase;
  }

  ${({ color }) => `color: ${color};`}
  ${({ variant, isMobile = false }) => handleType(variant, isMobile)};
`;
