import React from 'react';
import P from 'prop-types';

import { useWindowSize } from '@hooks/useWindowSize';
import * as S from './styled';

const mobileWidth = 768;

const Title = ({ variant, color, children }) => {
  const size = useWindowSize();

  return (
    <S.Title
      variant={variant}
      isMobile={size.width <= mobileWidth}
      color={color}
    >
      {children}
    </S.Title>
  );
};

Title.propTypes = {
  variant: P.oneOf(['', 'medium', 'regular', 'extra-light', 'light']),
  color: P.string,
  children: P.node,
};

Title.defaultProps = {
  color: '#fff',
};

export default Title;
