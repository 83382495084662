import React, { useState } from 'react';
import P from 'prop-types';

import { useContactContext } from '@context/contact-context';
import { Text, Title } from '../../atoms';
import { Tooltip } from '../../molecules';
import * as S from './styled';

const TextClipBoard = ({ text, children }) => {
  const [toolTipVisible, setToolTipVisible] = useState(false);
  const { messages } = useContactContext();

  function handleCopyToClipBoard() {
    try {
      navigator.clipboard.writeText(text);
      setToolTipVisible(true);

      const timeout = setTimeout(() => {
        setToolTipVisible(false);
        clearTimeout(timeout);
      }, 1600);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <S.Container>
      {text ? <Text color="#707070">{text}</Text> : null}
      <S.Label onClick={handleCopyToClipBoard}>
        <Tooltip text={messages['copied']} isVisible={toolTipVisible}>
          <Title color="#000000" variant="extra-light">
            {children}
          </Title>
        </Tooltip>
      </S.Label>
    </S.Container>
  );
};

TextClipBoard.propTypes = {
  text: P.string,
  children: P.node,
};

TextClipBoard.defaultProps = {
  text: '',
};

export default TextClipBoard;
