import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const Text = ({ weight, color, variant, children }) => {
  return (
    <S.Text weight={weight} color={color} variant={variant}>
      {children}
    </S.Text>
  );
};

Text.propTypes = {
  weight: P.number,
  color: P.string,
  variant: P.oneOf(['', 'medium']),
  children: P.node,
};

Text.defaultProps = {
  variant: '',
};

export default Text;
