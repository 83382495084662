import PropTypes from 'prop-types';
import React from 'react';

import * as S from './styled';

const Slide = ({ children }) => {
  const list = React.Children.toArray(children);

  return (
    <S.Container className="custom-slide">
      {list.map((elem, index) => (
        <S.Wrap key={index} className="custom-slide-wrap">
          {elem}
        </S.Wrap>
      ))}
    </S.Container>
  );
};

Slide.propTypes = {
  children: PropTypes.node,
};

export default Slide;
