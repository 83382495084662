import styled from 'styled-components';

export const Container = styled.button`
  width: 59px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: ${({ radius }) => radius};
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 1000px) {
    width: 45px;
    height: 70px;
  }
`;
