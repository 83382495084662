import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 523px;
`;

export const TitleWrap = styled.div`
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
    text-align: right;
  }
`;

export const Head = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 25px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const HeadText = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const ClipBoardWrap = styled.div`
  margin-bottom: 20px;
`;

export const TextActionWrap = styled.div`
  margin-bottom: 20px;
  border-radius: 10px;
  ${props =>
    props.disabled
      ? css`
          background: #ccc;
          cursor: not-allowed;
        `
      : null}
`;

export const ContainerImage = styled.div`
  position: relative;
`;

export const HandIcon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
