import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import * as Styled from './Map.styles.js';

// Utils
import { useCurrentLang, getLangParam } from '@utils/translate';
import { apiKeyGoogleMaps } from '@utils/constants';

// Assets
import MapStyles from './MapStyles.json';
import Logo from '@images/logo.svg';

function Map({ dimensions, info }) {
  const [lang, setLang] = useState(null);
  const currentLang = useCurrentLang();

  useEffect(() => {
    const language = getLangParam(currentLang);

    if (language) setLang(language.replace('_', '-'));
  }, []);

  if (!lang) return null;

  return (
    <Styled.MapContainer onMouseMove={e => e.stopPropagation()}>
      {!window?.google ? (
        <LoadScript googleMapsApiKey={apiKeyGoogleMaps} language={lang}>
          <GoogleMap
            mapContainerStyle={{
              width: dimensions.width,
              height: dimensions.height,
            }}
            center={{
              lat: info.latitude,
              lng: info.longitude,
            }}
            zoom={4}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              minZoom: 3,
              maxZoom: 20,
              styles: MapStyles,
            }}
          >
            <Marker
              icon={Logo}
              position={{ lat: info.latitude, lng: info.longitude }}
            />
          </GoogleMap>
        </LoadScript>
      ) : (
        <GoogleMap
          mapContainerStyle={{
            width: dimensions.width,
            height: dimensions.height,
          }}
          center={{
            lat: info.latitude,
            lng: info.longitude,
          }}
          zoom={4}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            minZoom: 3,
            maxZoom: 20,
            styles: MapStyles,
          }}
        >
          <Marker
            icon={Logo}
            position={{ lat: info.latitude, lng: info.longitude }}
          />
        </GoogleMap>
      )}
    </Styled.MapContainer>
  );
}

Map.propTypes = {
  dimensions: PropTypes.objectOf(PropTypes.string),
  info: PropTypes.objectOf(PropTypes.number),
  lang: PropTypes.string,
};

Map.defaultProps = {
  dimensions: {
    height: '238px',
    width: '100%',
  },
  info: { latitude: -18.1027, longitude: -44.45 },
};

export default Map;
