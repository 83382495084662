import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;

  @media only screen and (max-width: 1150px) {
    grid-column-gap: 35px;
  }

  @media only screen and (max-width: 1040px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 45px;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
`;

export const Wrap = styled.div`
  position: relative;

  + .custom-slide-wrap {
    &:after {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 4px;
      background: #e0e0e0;
      top: 0;
      left: -32px;
      @media only screen and (max-width: 1150px) {
        left: -20px;
      }
      @media only screen and (max-width: 1040px) {
        left: -25px;
      }
    }
  }
`;
