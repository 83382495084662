import styled, { keyframes, css } from 'styled-components';

const loading = keyframes`
from {
  background-image: linear-gradient(to right, #0f9688, #33e0ce, #0f9688, #33e0ce,#0f9688);
} to {
  background-position: 0%;
}
`;

const handleType = props => {
  switch (props.type) {
    case 'outlined':
      return css`
        border: 1px solid #707070;
        color: ${props.disabled ? '#fff' : '#000'};
        padding: 16px;
        text-decoration: underline;
        background-color: ${props.disabled ? '#ccc' : 'transparent'};
      `;
    default:
      return 'background-color: #0f9688; padding: 14px;';
  }
};

export const Button = styled.button`
  width: 100%;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  box-shadow: 0px 3px 14px #0000000f;
  border-radius: 10px;
  border: none;
  transition: 0.2s background;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  line-height: 19px;
  transition: all 0.2s ease-in-out;
  text-transform: ${props => (props.capitalize ? 'capitalize' : null)};

  ${props => {
    if (props.loading) {
      return css`
        background-image: linear-gradient(
          to right,
          #0f9688,
          #33e0ce,
          #0f9688,
          #33e0ce,
          #0f9688
        );
        background-size: 200%;
        background-position: 200%;
        background-color: transparent !important;
        animation: ${loading} 1s infinite 0s ease-in-out forwards;
      `;
    }
  }}

  &:active {
    transform: translateY(8px);
  }

  ${props => handleType(props, props)};

  ${type =>
    !type &&
    `&:hover {
      background: #0ead9c;
     }
  `}
`;
