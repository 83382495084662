import styled from 'styled-components';

export const TextAreaWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  width: 100%;
  background: transparent;
  backdrop-filter: blur(5px);
  box-shadow: 0px 3px 20px #000000aa;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #ffffff33;
  padding: 12px 17px;
  resize: none;
  flex: 1;

  &::placeholder {
    opacity: 1;
  }

  ${({ isError = false }) => isError && `border-color: #ff8585`}
`;

export const Error = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: #ff8585;
`;
