import React from 'react';
import P from 'prop-types';

import { Text, Title } from '../../atoms';
import * as S from './styled';

const TextAction = ({ text, handleClick, children, disabled }) => {
  return (
    <S.Container disabled={disabled}>
      <Text color={disabled ? '#fff' : '#000'} variant="medium" weight={600}>
        {text}
      </Text>
      <S.Label
        disabled={disabled}
        onClick={() => {
          if (!disabled) handleClick(text);
        }}
      >
        <Title color={disabled ? '#fff' : '#000'} variant="extra-light">
          {children}
        </Title>
      </S.Label>
    </S.Container>
  );
};

TextAction.propTypes = {
  text: P.string,
  handleClick: P.func,
  children: P.node,
  disabled: P.bool,
};

export default TextAction;
