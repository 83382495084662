import styled from 'styled-components';

const variants = {
  medium: 'font-size: 16px; line-height: 19px;',
  default: 'font-size: 14px; line-height: 18px; font-weight: 500;',
};

export const Text = styled.span`
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  &::first-letter {
    text-transform: uppercase;
  }

  color: ${({ color = '#fff' }) => color};
  font-weight: ${({ weight = 400 }) => weight};
  ${({ variant }) => variants[variant] ?? variants['default']};
`;
