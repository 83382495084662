import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const TextArea = ({ register = () => {}, schema = {}, errors, ...rest }) => {
  return (
    <S.TextAreaWrap className="textarea">
      <S.TextArea isError={!!errors} ref={register(schema)} {...rest} />
      {errors && <S.Error>{errors?.message}</S.Error>}
    </S.TextAreaWrap>
  );
};

TextArea.propTypes = {
  register: P.func,
  schema: P.object,
  errors: P.shape({
    message: P.string,
  }),
};

export default TextArea;
