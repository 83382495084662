import React from 'react';
import P from 'prop-types';
import { graphql } from 'gatsby';

// Components
import Layout from '@components/layout';
import SEO from '@components/seo';
import ContactPage from '@components/Contact';

// Utils
import polylangTranslationPathHelper from '@utils/polylangTranslationPathHelper';

const Contato = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: { contact_locals: locals, contact_hero: hero, contact_form: form },
    },
  } = data;
  const translations = polylangTranslationPathHelper(
    page.polylang_translations
  );
  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={translations}
      useTranslationsPath={true}
    >
      <SEO
        {...page}
        pageLang={page.polylang_current_lang}
        translations={translations}
      />
      <ContactPage />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        contact_hero {
          title
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        contact_locals {
          title
          addresses {
            state
            address1
            address2
            mapLink
            person {
              email
              person
              phone
              label
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        contact_form {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          form_subject {
            text
          }
        }
      }
    }
  }
`;

Contato.propTypes = {
  data: P.object,
  location: P.object,
};

export default Contato;
