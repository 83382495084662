import React from 'react';
import P from 'prop-types';
import * as S from './styled';

const Field = ({ register = () => {}, schema = {}, errors, ...rest }) => {
  return (
    <S.InputWrap>
      <S.Input isError={!!errors} ref={register(schema)} {...rest} />
      {errors && <S.Error>{errors?.message}</S.Error>}
    </S.InputWrap>
  );
};

Field.propTypes = {
  register: P.any,
  schema: P.object,
  errors: P.object,
};

export default Field;
