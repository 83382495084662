import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const IconButton = ({ children, onClick, radius }) => {
  return (
    <S.Container radius={radius} onClick={onClick}>
      {children}
    </S.Container>
  );
};

IconButton.propTypes = {
  children: P.node,
  onClick: P.func,
  radius: P.string,
};

IconButton.defaultProps = {
  radius: '13px',
};

export default IconButton;
