import React from 'react';
import P from 'prop-types';

import * as S from './styled';
import { translate as t } from '@utils/translate';

const Button = ({ children, loading, capitalize, disabled, ...rest }) => {
  const TEXTS = {
    LOADING: t('loading'),
  };

  return (
    <S.Button
      type="submit"
      disabled={disabled}
      loading={loading ? 1 : 0}
      capitalize={capitalize}
      {...rest}
    >
      {loading ? TEXTS.LOADING : children}
    </S.Button>
  );
};

Button.propTypes = {
  children: P.node,
  loading: P.bool,
  capitalize: P.bool,
  disabled: P.bool,
};

Button.defaultProps = {
  loading: false,
  disabled: false,
};

export default Button;
