import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 22px;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.div`
  cursor: pointer;
`;
