import React from 'react';
import P from 'prop-types';

import * as S from './styled';

const Tooltip = ({ text, isVisible, children }) => {
  return (
    <S.Container>
      {children}
      <S.Text isVisible={isVisible}>{text}</S.Text>
    </S.Container>
  );
};

Tooltip.propTypes = {
  text: P.string,
  isVisible: P.bool,
  children: P.node,
};

export default Tooltip;
