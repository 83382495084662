import styled from 'styled-components';

import heroContatImage from './assets/images/hero.jpg';

export const Container = styled.div`
  width: 100%;
  background: url(${heroContatImage}) center / cover no-repeat;
  position: relative;
`;

export const OvelayLeft = styled.div`
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(90deg, #000, transparent 48%); */
  position: absolute;
  left: 0;
  top: 0;
`;

export const OvelayRight = styled.div`
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(0deg, #000, transparent 20%); */
  position: absolute;
  left: 0;
  top: 0;
`;

export const Section = styled.section`
  width: 100%;
  padding: 160px 0 113px 0;
  position: relative;
  display: grid;
  grid-template-columns: auto 581px;
  grid-column-gap: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1120px;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 1280px) {
    padding-left: var(--container-size);
    padding-right: var(--container-size);
    max-width: unset;
  }

  @media only screen and (max-width: 1235px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 1014px) {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    padding: 136px 0 0 0;
  }

  > img {
    @media only screen and (max-width: 768px) {
      border-radius: 0;
    }
  }
`;

export const FormWrap = styled.div`
  max-width: 462px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1014px) {
    max-width: unset;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const TitleWrap = styled.h1`
  margin-bottom: 6px;
`;

export const SubTitleWrap = styled.p`
  max-width: 382px;
  margin-bottom: 6px;
`;

export const ImageBottom = styled.div`
  margin-top: 131px;
  margin-bottom: 80px;

  @media only screen and (max-width: 768px) {
    margin: 62px 0;
  }
`;

export const CardsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 4px solid #f1f1f1;

  padding: 0 var(--container-size) 0 var(--container-size);
`;

export const CardWrap = styled.div`
  width: 100%;
  padding: 36px 0;
  border-bottom: 4px solid #f1f1f1;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }
`;
