import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const ArrowLeft = ({ color, rotate }) => {
  return (
    <S.ArrowWrap rotate={rotate}>
      <svg xmlns="http://www.w3.org/2000/svg" width={12.472} height={20.225}>
        <path
          d="m0 2.36 7.753 7.752L0 17.865l2.36 2.36 10.112-10.113L2.36 0Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </S.ArrowWrap>
  );
};

ArrowLeft.propTypes = {
  color: PropTypes.string,
  rotate: PropTypes.number,
};

ArrowLeft.defaultProps = {
  color: '#201d20',
};

export default ArrowLeft;
