//
// Fix for urls using the same slug
// Ex: /app/, /en/app-2, /es/app-3
//
module.exports = translations => {
  return translations.map(translation => {
    translation.path = translation.path.replace(/(-\d*)\/$/, '/');
    return translation;
  });
};
