import { apiHost, contactInfo, sendMessageRoute } from '@utils/constants';
import useRequest from '@utils/useRequest';

/**
 *
 * @param {*} param0
 * @returns
 */
const getContactInfo = ({ params }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${contactInfo}`,
      useSession: false,
      method: 'get',
      params,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const sendMessage = ({ userName, emailAddress, subject, content }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${sendMessageRoute}`,
      useSession: false,
      method: 'post',
      data: {
        contactMessage: {
          userName,
          emailAddress,
          subject,
          content,
        },
      },
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export { getContactInfo, sendMessage };
