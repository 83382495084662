import React, { useState, useEffect, useRef } from 'react';
import Reaptcha from 'reaptcha';
import { useForm } from 'react-hook-form';
import { useContactContext } from '@context/contact-context';
import { Helmet } from 'react-helmet';

import { Button, Field, TextArea } from '../../../atoms';
import * as S from './styled';
import { translate as t, useCurrentLang } from '@utils/translate';
import { RECAPTCHA_SITE_KEY } from '@gatsby-local-plugin/gatsby-local-env-variables';

import { sendMessage } from '@services/contact';
import debounce from '@utils/debounce';

const FormContactUs = () => {
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      userName: '',
      emailAddress: '',
      content: '',
      emailConfirmation: '',
      subject: '',
    },
  });

  const lang = useCurrentLang();

  const captcha = useRef();

  const MESSAGES = {
    required: t('requiredField'),
    invalidEmail: t('emailIsNotValid'),
    invalidConfirmationEmail: t('emailDontMatch'),
    minMaxLength: t('minMaxLength'),
  };

  const TEXTS = {
    SUCCESS: t('success'),
    ERROR: t('error'),
  };

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');

  const { messages } = useContactContext();

  const verifyCaptcha = () => {
    captcha.current.getResponse().then(res => {
      if (res) {
        sendData();
      }
    });
  };

  const onSubmit = () => {
    captcha.current.getResponse().then(res => {
      if (res) {
        sendData();
      } else {
        captcha.current.execute();
      }
    });
  };

  const sendData = () => {
    setLoading(true);

    const data = getValues();

    debounce(async () => {
      delete data.emailConfirmation;
      const {
        data: { result },
      } = await sendMessage(data);
      if (result?.status === 0) {
        setFeedback(TEXTS.SUCCESS);
        reset();
      } else {
        setFeedback(TEXTS.ERROR);
      }
      setTimeout(() => {
        setFeedback('');
      }, 2500);
      setLoading(false);
    }, 1000);
  };

  const getMinMaxMessage = (fieldName, min, max) => {
    return MESSAGES.minMaxLength
      .replace('-FIELD-', fieldName)
      .replace('-MIN-', min)
      .replace('-MAX-', max);
  };

  useEffect(() => {
    clearError();
  }, [lang]);

  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
          async
          defer
        ></script>
      </Helmet>
      <S.FormContactUs onSubmit={handleSubmit(onSubmit)}>
        <Field
          data-test="contact.name"
          placeholder={messages['form.field.name']}
          name="userName"
          register={register}
          schema={{
            required: MESSAGES.required,
            maxLength: {
              value: 60,
              message: getMinMaxMessage(messages['form.field.name'], 3, 60),
            },
            minLength: {
              value: 3,
              message: getMinMaxMessage(messages['form.field.name'], 3, 60),
            },
          }}
          errors={errors?.userName}
        />
        <Field
          data-test="contact.email"
          placeholder={messages['form.field.email']}
          name="emailAddress"
          register={register}
          schema={{
            required: MESSAGES.required,
            maxLength: {
              value: 60,
              message: getMinMaxMessage(messages['form.field.email'], 3, 60),
            },
            minLength: {
              value: 3,
              message: getMinMaxMessage(messages['form.field.email'], 3, 60),
            },
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: MESSAGES.invalidEmail,
            },
          }}
          errors={errors?.emailAddress}
        />
        <TextArea
          data-test="contact.message"
          placeholder={messages['form.field.message']}
          name="content"
          register={register}
          schema={{
            maxLength: {
              value: 360,
              message: getMinMaxMessage(messages['form.field.message'], 3, 360),
            },
            minLength: {
              value: 3,
              message: getMinMaxMessage(messages['form.field.message'], 3, 360),
            },
          }}
          errors={errors?.content}
        />

        <Field
          data-test="contact.confirmEmail"
          placeholder={messages['form.field.emailconfirmation']}
          name="emailConfirmation"
          register={register}
          schema={{
            required: MESSAGES.required,
            validate: value => {
              const { emailAddress = '' } = getValues();

              if (!emailAddress) return true;

              return value === emailAddress
                ? true
                : MESSAGES.invalidConfirmationEmail;
            },
          }}
          errors={errors?.emailConfirmation}
        />
        <Field
          data-test="contact.subject"
          placeholder={messages['form.field.subject']}
          name="subject"
          register={register}
          schema={{
            required: MESSAGES.required,
            maxLength: {
              value: 60,
              message: getMinMaxMessage(messages['form.field.subject'], 3, 60),
            },
            minLength: {
              value: 3,
              message: getMinMaxMessage(messages['form.field.subject'], 3, 60),
            },
          }}
          errors={errors?.subject}
        />
        <Button loading={loading} data-test="contact.submit">
          {feedback ? (
            <S.Feedback>{feedback}!</S.Feedback>
          ) : (
            <span>{messages['form.field.button']}</span>
          )}
        </Button>
      </S.FormContactUs>
      <Reaptcha
        ref={captcha}
        sitekey={RECAPTCHA_SITE_KEY}
        onVerify={verifyCaptcha}
        size="invisible"
      />
    </>
  );
};

export default FormContactUs;
